import styled, { css } from 'styled-components';

import hexToRGBA from '../../../../../lib/HexToRGBA';
import { deprecated, fontLg2 } from '../../../../styled/TypeSystem';
import { EmojiWrapper } from '../../../shared/styles';
import { CardBackgroundStyle } from '../../shared/styles';

export const TopicHeaderContainer = styled.div<{ applyGap: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants }, applyGap }) => applyGap && constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const HeaderEmojiWrapper = styled(EmojiWrapper)`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthMd} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  height: 4rem;

  ${fontLg2}
`;

export const CenterTextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translate(-50%, -50%);
  color: ${({ theme: { vars } }) => vars.textSurface};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => hexToRGBA(vars.foundationSurface2, 0.4)};

  ${deprecated.fontSm3};
`;

export const SharedImageStyles = css`
  width: 100%;
  height: 12.5rem;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) =>
    `${constants.borderRadiusXl} ${constants.borderRadiusXl} 0 0`};
`;

export const CoverPhoto = styled.img`
  align-self: center;
  object-fit: cover;
  ${SharedImageStyles};
`;

export const ImageWrapper = styled.div<{ applyAdditionalMargin: boolean }>`
  height: fit-content;
  position: relative;
  margin-bottom: -${({ theme: { constants }, applyAdditionalMargin }) => (applyAdditionalMargin ? constants.spacerLg2 : constants.spacerMd2)};
`;

export const CardBackgroundTopicTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  ${CardBackgroundStyle};
`;
