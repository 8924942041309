import React from 'react';
import styled, { css } from 'styled-components';

import { mediaBreakpointPxLg } from '../../../../styled/Breakpoint';
import { deprecated, fontMd5 } from '../../../../styled/TypeSystem';
import { CardBackgroundTopicTitle, HeaderEmojiWrapper } from '../../EditorPage/TopicHeader/styles';
import { LabelWrapper } from '../styles';

const CourseTitle = styled.span<{ removeTitleMargin: boolean }>`
  width: 100%;
  height: fit-content;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  cursor: default;

  ${({ removeTitleMargin, theme: { constants } }) =>
    removeTitleMargin
      ? css`
          padding: ${constants.spacerSm3} 0 ${constants.spacerMd1} 0;
        `
      : css`
          padding: ${constants.spacerSm2} 0 0 0;
          margin-top: ${constants.spacerMd3};
        `};

  @media (min-width: ${mediaBreakpointPxLg}) {
    ${deprecated.fontLg3};
  }

  ${fontMd5};
`;

const StyledCardBackgroundTopicTitle = styled(CardBackgroundTopicTitle)<{
  addEmojiSpacing: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ addEmojiSpacing, theme: { constants } }) =>
    addEmojiSpacing &&
    css`
      margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
      padding: 0 ${constants.spacerLg1} ${constants.spacerLg1} ${constants.spacerLg1};
    `}
`;

const EmojiContainer = styled.div<{ hasCover: boolean }>`
  position: absolute;
  top: -35%;
  z-index: 1;
`;

const Emoji = styled(HeaderEmojiWrapper)`
  cursor: default;
`;

type Props = {
  title: string;
  emoji: string | null;
  hasCoverImage: boolean;
  topicTerm: string;
};

const TopicTitle = ({ title, hasCoverImage, emoji, topicTerm }: Props) => {
  return (
    <StyledCardBackgroundTopicTitle
      addEmojiSpacing={!hasCoverImage && !!emoji}
      isFlatTop={hasCoverImage}
    >
      {emoji && (
        <EmojiContainer className='emoji-container' hasCover={hasCoverImage}>
          <Emoji>{emoji}</Emoji>
        </EmojiContainer>
      )}
      <CourseTitle removeTitleMargin={!emoji || hasCoverImage}>{title}</CourseTitle>
      <LabelWrapper>{topicTerm}</LabelWrapper>
    </StyledCardBackgroundTopicTitle>
  );
};

export default TopicTitle;
